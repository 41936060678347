import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import {media} from '../../../utils'


const StyledContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  margin: 0 5rem;
  padding: 6.5rem 0 8rem;
  text-align: center;

  ${media.down('mobile')(css`
    display: flex;
    flex-direction: column;
    margin: 0 2rem;
    padding: 2.5rem 0 0;
  `)}

  > :first-child {
    margin-bottom: 3.5rem;
  }

  > * {
    width: 45%;

    ${media.down('mobile')(css`
      width: 100%;
    `)}
  }
`

const DescriptionContainer = ({children, ...props}) => (
  <StyledContainer {...props}>
    {children}
  </StyledContainer>
)

DescriptionContainer.propTypes = {
  children: PropTypes.node.isRequired,
}

export default DescriptionContainer
