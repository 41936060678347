import {graphql} from 'gatsby'
import PropTypes from 'prop-types'
import {map, mapValues} from 'lodash-es'
import allSettings from '../../content/settings/general.json'
import Section from '../components/containers/Section'
import Typography from '../components/atoms/Typography'
import DescriptionContainer from '../components/containers/DescriptionContainer'
import AppShell from '../components/containers/AppShell'
import useLang from '../hooks/useLang'
import HomePageHero from '../components/molecules/HomePageHero'
import ReferenceRelation from '../components/molecules/ReferenceRelation'
import Navbar from '../components/containers/Navbar'
import SideTitleSection from '../components/containers/SideTitleSection'
import ServicesRelation from '../components/containers/ServicesRelation'


const Index = ({data, pageContext: {lang: pageLang}}) => {
  const langToSlug = mapValues(data?.markdownRemark.frontmatter, 'slug')
  const {lang} = useLang(pageLang, langToSlug)
  const pageData = data?.markdownRemark.frontmatter[lang]
  const services = map(data?.services.edges, ({node}) => node.frontmatter[lang])
  const settings = allSettings[lang]

  return (
    <AppShell title={pageData?.title} lang={lang} langToSlug={langToSlug} seo={pageData?.seo}>
      <HomePageHero
          featuredImages={pageData?.hero.featuredImages}
          slideButtonTitle={pageData?.hero.servicesReferenceText}
          sliderSettings={settings.slider}
      >
        <Navbar lang={lang} langToSlug={langToSlug} />
      </HomePageHero>
      <main>
        <Typography type="h1" srOnly>{pageData?.title}</Typography>
        <Section>
          <DescriptionContainer>
            <Typography variant="h2" type="h6" thin>{pageData?.descriptionSection.title}</Typography>
            <Typography>{pageData?.descriptionSection.description}</Typography>
          </DescriptionContainer>
        </Section>
        <SideTitleSection
            title={pageData.services.title}
            fadedSideText={pageData.services.sideText}
            mobileFadedSideText
            id="sectionServices"
        >
          <ServicesRelation
              allServicesText={pageData.services.allServicesText}
              allServicesLink={pageData.services.allServicesLink}
              services={services}
              sliderSettings={settings.slider}
          />
        </SideTitleSection>
        <SideTitleSection
            title={pageData.references.title}
            mobileTitleVisible
            mobileFadedSideText
            fadedSideText={pageData.references.sideText}
        >
          <ReferenceRelation
              lang={lang}
              logos={pageData.references.logos}
              allReferencesText={pageData.references.allReferencesText}
              allReferencesLink={pageData.references.allReferencesLink}
          />
        </SideTitleSection>
      </main>
    </AppShell>
  )
}

export const data = graphql`
  query Index($id: String!, $services: [String!]) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        ...IndexPageFragment
      }
    }

    services: allMarkdownRemark (
      filter: {frontmatter: {cs: {slug: {in: $services}}}}
    ) {
      edges {
        node {
          frontmatter {
            ...ServicePageFragment
          }
        }
      }
    }
  }
`

Index.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.shape({
    lang: PropTypes.string.isRequired,
  }).isRequired,
}

export default Index
